.App {
  text-align: center;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
h1 {
  font-size: 48px;
  font-family: "Times New Roman", serif, Noteworthy;
}
.App h2,h1 {
  color: white;
}
h2 {
  margin-top: -10px;
}
#b1 {
  width: fit-content;
  align-self: center;
  border-radius: 10px;
  font-size: 32px;
  color: white;
  background-color: coral;
  padding: 10px;
  margin-top: 50px;
}

body {
  background-color: #2e2e72;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
